export const Input = ({ label, type, emailBody, className }) => {
  return (
    <input
      className={className}
      required
      name={emailBody[label]}
      type={type}
      placeholder={`Your ${label}...`}
    />
  );
};

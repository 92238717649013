import "../style/SubHeader.css";
import logo from "../svg/logo-black.svg";

export const SubHeader = () => {
  return (
    <div className="sub-header">
      <div className="logo">
        <img src={logo} alt="" />
      </div>
      <p className="description p" id="artists">
        Hoop Records is an independent Italian label mainly focused on electro
        pop and dance sonorities plus they are opened to other music influences
        with positiveness and good vibes.
      </p>
    </div>
  );
};

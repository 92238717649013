import "../style/Button.css";

export const Button = ({ children, className, type }) => {
  return (
    <button
      type={type ?? "button"}
      className={`button ${className} unselectable`}
    >
      {children}
    </button>
  );
};

import { sendEmail } from "./mailer";

export function getList(snapshot) {
  return snapshot.docs.map((doc) => {
    return { id: doc.id, ...doc.data };
  });
}

export async function handleSubmitForm(e, title, formRef) {
  e.preventDefault();

  const formData = new FormData(e.target);
  const payload = Object.fromEntries(formData);
  payload.subject = title;
  try {
    await sendEmail(payload);
    formRef.current.reset();
  } catch (error) {
    formRef.current.reset();
    console.log("Something went wrong", error, error.message);
  }
}

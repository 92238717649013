export const Select = ({ options, emailBody, label }) => {
  return (
    <select required name={emailBody[label]} id="">
      <option value="" disabled selected>
        Select your {label}
      </option>
      {options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
};

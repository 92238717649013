import { createBrowserRouter } from "react-router-dom";
import { SendYourDemo, TradeRequest, StudioRequest } from "./mainComponents";
import App from "./App";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/demo",
    element: <SendYourDemo />,
  },
  {
    path: "/trade",
    element: <TradeRequest />,
  },
  {
    path: "/studio",
    element: <StudioRequest />,
  },
]);

export const SocialLinks = ({ socials, className }) => {
  return (
    <div className={className}>
      {socials.map((social) => {
        return (
          <a href={social.link} target="_blank" key={social.src}>
            <img src={social.src} alt="social icon" />
          </a>
        );
      })}
    </div>
  );
};

export const JobDescription = ({
  open,
  shortDescription,
  description,
  qualities,
}) => {
  return (
    <div id="job-description" className={`${open ? "opened" : "closed"}`}>
      {shortDescription}
      <br />
      <br />
      {description}
      <br />
      <h4 className="job-qualities-title">Who you are:</h4>
      <ul className="job-qualities">
        {qualities && qualities.map((quality, i) => <li key={i}>{quality}</li>)}
      </ul>
      <br />
      <p>
        <strong>
          Do you want to join Hoop Records family ? Don’t lose time and send
          your resume and cover letter to{" "}
          <span className="email">info@hoop-records.com</span>
        </strong>
      </p>
    </div>
  );
};

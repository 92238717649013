export const JobHeader = ({ open, setOpen, tags, title }) => {
  return (
    <div
      className={`job-header ${open ? "opened" : ""}`}
      onClick={() => {
        setOpen((prev) => !prev);
      }}
    >
      <div className="job-title">{title}</div>
      <ul className="job-tags">
        {tags &&
          tags.map((tag, i) => (
            <li className="job-tag" key={i}>
              {tag}
            </li>
          ))}
      </ul>
      <div className={`circle-plus ${open ? "opened" : "closed"} `}>
        <div className="circle">
          <div className="horizontal"></div>
          <div className="vertical"></div>
        </div>
      </div>
    </div>
  );
};

import { useContext, useState, createContext } from "react";

export const DropDownMenuContext = createContext();
export const DropDownMenuUpdateContext = createContext();

export function useDropDownMenu() {
  return useContext(DropDownMenuContext);
}

export function useDropDownMenuUpdate() {
  return useContext(DropDownMenuUpdateContext);
}

export const DropDownMenuProvider = ({ children }) => {
  const [dropDownMenuState, setDropDownMenuState] = useState(false);

  function toggleDropDownMenu() {
    setDropDownMenuState((prev) => !prev);
  }

  return (
    <DropDownMenuContext.Provider value={dropDownMenuState}>
      <DropDownMenuUpdateContext.Provider value={toggleDropDownMenu}>
        {children}
      </DropDownMenuUpdateContext.Provider>
    </DropDownMenuContext.Provider>
  );
};

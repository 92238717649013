import { HoopForm } from "../components/HoopForm";
import { handleSubmitForm } from "../api/utils";
import { useRef } from "react";

export const TradeRequest = () => {
  const formRef = useRef(null);
  return (
    <HoopForm
      formRef={formRef}
      handleSubmit={(e) => handleSubmitForm(e, "TRADE REQUEST", formRef)}
      title={"TRADE"}
      subtitle={"Playlists"}
      inputs={[
        { label: "name", type: "text", input: "input" },
        { label: "email", type: "email", input: "input" },
        { label: "track/playlist url", type: "text", input: "input" },
        {
          label: "user type",
          input: "select",
          options: [
            "label",
            "curator",
            "manager",
            "private",
            "network",
            "other",
          ],
        },
      ]}
      textarea={"short description"}
      theme={"dark"}
      emailBody={{
        name: "name",
        email: "email",
        "user type": "userType",
        "track/playlist url": "playlistUrl",
        "short description": "message",
      }}
    ></HoopForm>
  );
};

import "../style/Pic.css";

export const Pic = ({ name, cit, setCit, fullname, title, children }) => {
  return (
    <div className="pic-container">
      <div className="pic-description">
        <div
          onClick={() =>
            setCit((prev) => {
              return prev === name ? "" : name;
            })
          }
          className={`${name}-pic pic ${cit === name ? "pic-open" : ""}`}
        ></div>
        <h3>{fullname}</h3>
        <h4>{title}</h4>
      </div>
      <p className={`p cit ${cit === name ? "open" : ""}`}>
        <span className="quote">"</span>
        {children}
        <span className="quote">"</span>
      </p>
    </div>
  );
};

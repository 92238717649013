import { handleSubmitForm } from "../api/utils";
import { HoopForm } from "../components/HoopForm";
import { useRef } from "react";

export const SendYourDemo = () => {
  const formRef = useRef(null);

  return (
    <HoopForm
      title={"DEMO"}
      formRef={formRef}
      subtitle={"send your"}
      inputs={[
        { label: "name", type: "text", input: "input" },
        { label: "email", type: "email", input: "input" },
        { label: "demo url", type: "text", input: "input" },
        { label: "social link", type: "text", input: "input" },
      ]}
      emailBody={{
        name: "name",
        email: "email",
        "demo url": "demoUrl",
        "social link": "socialLink",
        info: "message",
      }}
      textarea={"info"}
      theme={"light"}
      handleSubmit={(e) => handleSubmitForm(e, "DEMO", formRef)}
    ></HoopForm>
  );
};

export async function sendEmail(email) {
  const response = await fetch(process.env.REACT_APP_API_URL + "/submit", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": process.env.REACT_APP_API_URL,
    },
    body: JSON.stringify(email),
  });
  return response.json();
}

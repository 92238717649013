import "../style/Studio.css";
import mixing from "../svg/mixing.svg";
import production from "../svg/production.svg";
import master from "../svg/master.svg";
import { ButtonGradient } from "../components/ButtonGradient";
import { Link } from "react-router-dom";

export const Studio = () => {
  return (
    <section id="studio" className="studio-section">
      <h2 className="section-title studio-title">STUDIO</h2>
      <div className="studio-icons">
        <div className="studio-icon unselectable">
          <img src={mixing} alt="mixing" />
          <h3>Mixing</h3>
        </div>
        <div className="studio-icon unselectable">
          <img src={master} alt="master" />
          <h3>Master</h3>
        </div>
        <div className="studio-icon unselectable">
          <img src={production} alt="production" />
          <h3>Production</h3>
        </div>
      </div>
      <Link to="/studio">
        <ButtonGradient className={"studio-cta"}>send a request</ButtonGradient>
      </Link>
    </section>
  );
};

import { useDropDownMenu, useDropDownMenuUpdate } from "../context";
import "../style/DropDownMenu.css";

export const DropDownMenu = () => {
  const dropDownOpen = useDropDownMenu();
  const setDropDownOpen = useDropDownMenuUpdate();

  return (
    <div
      id="dropdown"
      className={dropDownOpen ? "dropdown-open" : "dropdown-close"}
    >
      <ul className="dropdown-nav-link">
        <li>
          <a
            href="#home"
            onClick={() => {
              setDropDownOpen();
            }}
          >
            Home
          </a>
        </li>
        <li>
          <a
            href="#artists"
            onClick={() => {
              setDropDownOpen();
            }}
          >
            Artists
          </a>
        </li>
        <li>
          <a
            href="#playlists"
            onClick={() => {
              setDropDownOpen();
            }}
          >
            Playlists
          </a>
        </li>
        <li>
          <a
            href="#studio"
            onClick={() => {
              setDropDownOpen();
            }}
          >
            Studio
          </a>
        </li>
        <li>
          <a
            href="#jobs"
            onClick={() => {
              setDropDownOpen();
            }}
          >
            Jobs
          </a>
        </li>
        <li>
          <a
            href="#about"
            onClick={() => {
              setDropDownOpen();
            }}
          >
            About Us
          </a>
        </li>
      </ul>
    </div>
  );
};

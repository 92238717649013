export async function fetchJobs() {
  const response = await fetch(process.env.REACT_APP_API_URL + "/jobs", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": process.env.REACT_APP_API_URL,
    },
  });
  return response.json();
}

import { HoopForm } from "../components/HoopForm";

import { useRef } from "react";
import { handleSubmitForm } from "../api/utils";

export const StudioRequest = () => {
  const formRef = useRef(null);

  return (
    <HoopForm
      handleSubmit={(e) => handleSubmitForm(e, "STUDIO REQUEST", formRef)}
      formRef={formRef}
      title={"REQUEST"}
      subtitle={"send your"}
      inputs={[
        { label: "name", type: "text", input: "input" },
        { label: "email", type: "email", input: "input" },
        {
          label: "request type",
          input: "select",
          options: ["mixing", "master", "production", "other"],
        },
        { label: "track link", type: "text", input: "input" },
      ]}
      textarea={"request"}
      theme={"dark"}
      emailBody={{
        name: "name",
        email: "email",
        "request type": "requestType",
        request: "message",
        "track link": "trackLink",
      }}
    ></HoopForm>
  );
};

import '../style/Cta.css';
import { ButtonGradient } from '../components/ButtonGradient';
import downArrow from '../svg/down-arrow.svg';
import { Link } from 'react-router-dom';

export const Cta = () => {
  return (
    <section className='cta-section'>
      <img src={downArrow} alt='arrow' className='cta-arrow' />
      <Link to='/demo'>
        <ButtonGradient className={'cta-button'}>SEND YOUR DEMO</ButtonGradient>
      </Link>
    </section>
  );
};

// import logo from './logo.svg';
import "./App.css";

import {
  Header,
  Artists,
  Playlists,
  Studio,
  AboutUs,
  Cta,
  ContactUs,
  Footer,
} from "./mainComponents";
import { Jobs } from "./mainComponents/Jobs";

function App() {
  return (
    <div className="App">
      <Header />
      <Artists />
      <Playlists />
      <Studio />
      <Jobs />
      <AboutUs />
      <Cta />
      <ContactUs />
      <Footer />
    </div>
  );
}

export default App;

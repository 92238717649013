import { Pic } from "../components/Pic";

export const AboutUsPics = ({ cit, setCit }) => {
  return (
    <div className="about-us-pics">
      <Pic
        name={"fabiano"}
        cit={cit}
        setCit={setCit}
        fullname={"Fabiano Rotondo"}
        title={"CEO & CO-Founder"}
      >
        Hoop Records is more than a label, it is an attitude in rejecting
        negative feelings to let people experience positivity and good
        vibrations in their lives; for this reason, our motto is “Feels so
        good”, fully represented by the sonorities expressed by our music.
      </Pic>
      <Pic
        name={"enrico"}
        cit={cit}
        setCit={setCit}
        fullname={"Enrico Capano"}
        title={"COO & CO-Founder"}
      >
        Our intent is to grow a community of artists, producers and music lovers
        united by a huge passion for the music and all the shades around it.
      </Pic>
      <Pic
        name={"frank"}
        cit={cit}
        setCit={setCit}
        fullname={"Frank Martusciello"}
        title={"Press & Promotion"}
      >
        Discover new talents from around the world and, through their music
        spreading energy, happiness, and positive vibes, that is our mission.
      </Pic>
      <Pic
        name={"francesco"}
        cit={cit}
        setCit={setCit}
        fullname={"Francesco Luongo"}
        title={"Graphic Designer"}
      >
        When you look at our logo, our artworks, and our sound you can
        immediatel understand our philosophy and the way we interpret, not only
        music, but life in general.
      </Pic>
    </div>
  );
};

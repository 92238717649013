import "../style/Header.css";
import { Navbar } from "../components/Navbar";
import { HeaderBanner } from "../components/HeaderBanner";
import { SubHeader } from "../components/SubHeader";
import { DropDownMenu } from "../components/DropDownMenu";

export const Header = () => {
  return (
    <header id="home" className="main-header">
      <Navbar />
      <DropDownMenu />
      <HeaderBanner />
      <SubHeader />
    </header>
  );
};

import "../style/Playlists.css";
import { Button } from "../components/Button";
import { Link } from "react-router-dom";

export const Playlists = () => {
  return (
    <section id="playlists" className="playlists-section">
      <div className="playlists-image"></div>

      <h2 className="section-title playlists-title">PLAYLISTS</h2>

      <div className="first-text section-p">
        <p className="p">
          Enjoy our playlists if you want to be carried by good music and
          positive vibes.
        </p>
        <Link
          to={"https://open.spotify.com/user/hoop__records?si=6929b5e3dd7c4111"}
          target="_blank"
        >
          <Button>listen our playlists</Button>
        </Link>
      </div>

      <div className="second-text section-p">
        <p className="p">
          We are open to playlist trade, so click on the button below and get in
          touch with us.
        </p>
        <Link to={"/trade"}>
          <Button>playlists trade request</Button>
        </Link>
      </div>
    </section>
  );
};

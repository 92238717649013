import "../style/NavLink.css";

export const NavLink = ({ className }) => {
  return (
    <ul className={className}>
      <li>
        <a href="#home">Home</a>
      </li>
      <li>
        <a href="#artists">Artists</a>
      </li>
      <li>
        <a href="#playlists">Playlists</a>
      </li>
      <li>
        <a href="#studio">Studio</a>
      </li>
      <li>
        <a href="#jobs">Jobs</a>
      </li>
      <li>
        <a href="#about">About Us</a>
      </li>
    </ul>
  );
};

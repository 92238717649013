import { ButtonGradient } from "./ButtonGradient";
import "../style/Form.css";
import backArrow from "../svg/back-arrow.svg";
import { InputRow } from "./InputRow";
import { useNavigate } from "react-router-dom";

export const HoopForm = ({
  title,
  subtitle,
  inputs,
  textarea,
  theme,
  handleSubmit,
  emailBody,
  formRef,
}) => {
  const isDark = () => {
    return theme === "dark";
  };
  const navigate = useNavigate();

  return (
    <main className={`demo-main ${isDark() ? "demo-main-dark" : ""}`}>
      <button
        onClick={() => {
          navigate(-1);
        }}
        className="back-arrow"
      >
        <img src={backArrow} alt="back arrow" />
      </button>
      <div className="demo-wrapper">
        <h1 className="section-title">
          {subtitle} <br />
          <span>{title}</span>
        </h1>
        <form
          ref={formRef}
          action=""
          className="demo-form"
          onSubmit={handleSubmit}
        >
          {inputs.map((el, i) => {
            if (i % 2 !== 0 || !el) return undefined;
            return (
              <InputRow
                emailBody={emailBody}
                key={i}
                el={el}
                i={i}
                inputs={inputs}
                isDark={isDark}
              />
            );
          })}

          <div className="label-textarea">
            <label
              className={`${isDark() ? "label-dark" : ""}`}
              htmlFor={emailBody[textarea]}
            >
              {textarea}
            </label>

            <textarea
              required
              placeholder={`Your ${textarea}`}
              name={emailBody[textarea]}
              id=""
              cols="30"
              rows="5"
            ></textarea>
          </div>

          <ButtonGradient
            className={` ${isDark() ? "demo-button-dark" : "demo-button"}`}
          >
            send it
          </ButtonGradient>
        </form>
      </div>
    </main>
  );
};

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./fonts/Archivo-VariableFont.ttf";
import "./fonts/Archivo-Italic-VariableFont.ttf";
import { DropDownMenuProvider } from "./context";
import { RouterProvider } from "react-router-dom";
import { router } from "./router";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <DropDownMenuProvider>
      <RouterProvider router={router} />
    </DropDownMenuProvider>
  </React.StrictMode>
);

import "../style/ContactUs.css";
import { Button } from "../components/Button";
import rightArrow from "../svg/arrow.svg";
import { handleSubmitForm } from "../api/utils";
import { useRef } from "react";
import { Input } from "../components/Input";

//TODO add email
export const ContactUs = () => {
  const formRef = useRef(null);

  function handleSubmit(e) {
    handleSubmitForm(e, "CONTACT", formRef);
  }

  return (
    <section id="contact" className="contact-us-section">
      <div className="contact-us-text">
        <h2 className="section-title contact-us-title">CONTACT US</h2>
        <p className="p">Get in touch with us by sending a message.</p>
      </div>

      <form
        ref={formRef}
        action=""
        className="contact-us-form"
        onSubmit={handleSubmit}
      >
        <Input
          className={""}
          emailBody={{ email: "email" }}
          type={"email"}
          label={"email"}
        />
        <textarea
          required
          name="info"
          id=""
          cols="30"
          rows="5"
          placeholder="Text here..."
        ></textarea>

        <Button className={"contact-us-button"} type={"submit"}>
          send it <img src={rightArrow} alt="arrow" />
        </Button>
      </form>
    </section>
  );
};

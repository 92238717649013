import logo from "../svg/logo.svg";
import "../style/Navbar.css";
import { Burger } from "./Burger";
import { NavLink } from "./NavLink";
import { ButtonGradient } from "./ButtonGradient";
import { Link } from "react-router-dom";

export const Navbar = () => {
  return (
    <nav className="main-nav">
      <div className="logo">
        <a href="#home">
          <img src={logo} alt="" />
        </a>
      </div>
      <div className="main-nav-right">
        <NavLink className={"nav-link"} />
        <Link to="/demo">
          <ButtonGradient className={"navbar-cta"}>
            SEND YOUR DEMO
          </ButtonGradient>
        </Link>
        <Burger />
      </div>
    </nav>
  );
};

import { useEffect, useState } from "react";
import { fetchJobs } from "../api/jobs";
import "../style/Jobs.css";
import { Job } from "../components/Job";

export const Jobs = () => {
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    try {
      fetchJobs().then((jobs) => {
        setJobs(jobs);
      });
    } catch (error) {
      console.log("Something went wrong fetching jobs:", error.message);
    }
  }, []);

  return (
    <section id="jobs" className="jobs-section">
      <h2 className="section-title">JOIN OUR FAMILY</h2>
      <div className="jobs">
        {jobs.length > 0 ? (
          jobs.map((job) => {
            return <Job job={job} key={job.id} />;
          })
        ) : (
          <div>No jobs available at the moment</div>
        )}
      </div>
    </section>
  );
};

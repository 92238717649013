import { Input } from "./Input";
import { Select } from "./Select";

export const InputRow = ({ isDark, el, inputs, i, emailBody }) => {
  const secondEl = inputs[i + 1];

  return (
    <div className="row">
      <div className="label-input">
        <label
          htmlFor={emailBody[el.label]}
          className={`${isDark() ? "label-dark" : ""}`}
        >
          {el.label}
        </label>
        {el.input === "input" && (
          <Input label={el.label} emailBody={emailBody} type={el.type} />
        )}
        {el.input === "select" && (
          <Select label={el.label} emailBody={emailBody} options={el.options} />
        )}
      </div>
      {secondEl ? (
        <div className="label-input">
          <label
            className={`${isDark() ? "label-dark" : ""}`}
            htmlFor={emailBody[secondEl.label]}
          >
            {secondEl.label}
          </label>

          {secondEl.input === "input" && (
            <Input
              label={secondEl.label}
              emailBody={emailBody}
              type={secondEl.type}
            />
          )}
          {secondEl.input === "select" && (
            <Select
              label={secondEl.label}
              emailBody={emailBody}
              options={secondEl.options}
            />
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

import { Link } from "react-router-dom";
import "../style/HeaderBanner.css";
import { Button } from "./Button";

export const HeaderBanner = () => {
  return (
    <div className="header">
      <div className="title unselectable">
        <h2 className="feel-so">FEEL SO</h2>
        <h2 className="good">GOOD</h2>
        <Link to="/demo">
          <Button className={"cta-header"}>send your DEMO</Button>
        </Link>
      </div>
    </div>
  );
};

import { useEffect, useState } from "react";
import { CustomSwiper } from "../components/CustomSwiper";
import "../style/Artists.css";
import arrow from "../svg/arrow.svg";
import { fetchArtists } from "../api/artists";

export const Artists = () => {
  const [artists, setArtists] = useState([]);

  useEffect(() => {
    try {
      fetchArtists().then((artists) => {
        setArtists(artists);
      });
    } catch (error) {
      console.log("Something went wrong fetching artists:", error.message);
    }
  }, []);

  return (
    <section className="artists-section">
      <h2 className="section-title">ARTISTS</h2>
      <div className="swiper-custom-container unselectable">
        <div className="slider-controler">
          <div className="swiper-button-prev slider-arrow">
            <img src={arrow} alt="" />
          </div>
        </div>
        <CustomSwiper artists={artists} />
        <div className="slider-controler">
          <div className="swiper-button-next slider-arrow">
            <img src={arrow} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

import { useState } from "react";
import { JobHeader } from "./JobHeader";
import { JobDescription } from "./JobDescription";

export const Job = ({ job }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className="job">
      <JobHeader
        title={job.title}
        tags={job.tags}
        open={open}
        setOpen={setOpen}
      />
      <JobDescription
        shortDescription={job.shortDescription}
        description={job.description}
        open={open}
        qualities={job.qualities}
      />
    </div>
  );
};

export const AboutUsDescription = ({ cit }) => {
  return (
    <div className="about-us-description">
      <p className={`p desktop-cit ${cit === "fabiano" ? "open" : ""}`}>
        <span className="quote">"</span>
        Hoop Records is more than a label, it is an attitude in rejecting
        negative feelings to let people experience positivity and good
        vibrations in their lives; for this reason, our motto is “Feels so
        good”, fully represented by the sonorities expressed by our music.
        <span className="quote">"</span>
      </p>
      <p className={`p desktop-cit ${cit === "enrico" ? "open" : ""}`}>
        <span className="quote">"</span>
        Our intent is to grow a community of artists, producers and music lovers
        united by a huge passion for the music and all the shades around it.
        <span className="quote">"</span>
      </p>
      <p className={`p desktop-cit ${cit === "frank" ? "open" : ""}`}>
        <span className="quote">"</span>
        Discover new talents from around the world and, through their music
        spreading energy, happiness, and positive vibes, that is our mission.
        <span className="quote">"</span>
      </p>
      <p className={`p desktop-cit ${cit === "francesco" ? "open" : ""}`}>
        <span className="quote">"</span>
        When you look at our logo, our artworks, and our sound you can
        immediatel understand our philosophy and the way we interpret, not only
        music, but life in general.
        <span className="quote">"</span>
      </p>
    </div>
  );
};

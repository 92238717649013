import { useDropDownMenu, useDropDownMenuUpdate } from "../context";
import "../style/Burger.css";

export const Burger = () => {
  const burgerOpen = useDropDownMenu();
  const setBurgerOpen = useDropDownMenuUpdate();

  return (
    <div
      id="nav-icon"
      className={burgerOpen ? "open" : ""}
      onClick={setBurgerOpen}
    >
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
};

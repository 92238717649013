import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Navigation, EffectCoverflow, Pagination } from "swiper";
import "swiper/css/navigation";
import "swiper/css/effect-coverflow";

export const CustomSwiper = ({ artists }) => {
  return (
    <Swiper
      style={{
        "--swiper-navigation-color": "rgb(51,51,51)",
        "--swiper-pagination-color": "rgb(51,51,51)",
      }}
      slidesPerView={2}
      spaceBetween={30}
      effect={"coverflow"}
      coverflowEffect={{
        rotate: 0,
        stretch: 0,
        depth: 100,
        modifier: 2.5,
      }}
      navigation={{
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
        clickable: true,
      }}
      pagination={{ el: ".swiper-pagination", clickable: true }}
      centeredSlides={true}
      loop={true}
      slidesPerGroupSkip={1}
      grabCursor={true}
      modules={[Navigation, Pagination, EffectCoverflow]}
      className="swiper_container"
    >
      {artists.map((artist) => {
        return (
          <SwiperSlide key={artist.id}>
            <a
              href={artist.spotifyLink}
              target="_blank"
              className="artists-pic"
            >
              <img
                src={`${artist.imageUrl}`}
                alt={artist.name}
                loading="lazy"
              />
            </a>
            <div className="swiper-lazy-preloader"></div>
            <div className="artists-name">
              <h3>{artist.name}</h3>
            </div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

import "../style/Footer.css";
import { Button } from "../components/Button";
import "../style/Footer.css";
import logo from "../svg/big-logo-white.svg";
import facebook from "../svg/facebook-white.svg";
import instagram from "../svg/instagram-white.svg";
import tiktok from "../svg/tiktok-white.svg";
import spotify from "../svg/spotify-white.svg";
import youtube from "../svg/youtube-white.svg";
import { SocialLinks } from "../components/SocialLinks";
import cloud from "../svg/cloud-white.svg";
import { NavLink } from "../components/NavLink";
import { Link } from "react-router-dom";

export const Footer = () => {
  const socialMap = [
    { src: facebook, link: process.env.REACT_APP_FACEBOOK_URL },
    { src: instagram, link: process.env.REACT_APP_INSTAGRAM_URL },
    { src: tiktok, link: process.env.REACT_APP_TIKTOK_URL },
    { src: spotify, link: process.env.REACT_APP_SPOTIFY_URL },
    { src: youtube, link: process.env.REACT_APP_YOUTUBE_URL },
    { src: cloud, link: process.env.REACT_APP_SOUNDCLOUD_URL },
  ];
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-left">
          <img src={logo} alt="" className="footer-logo unselectable" />
          <NavLink className={"footer-links"} />
        </div>
        <div className="footer-right">
          <SocialLinks
            socials={socialMap}
            className={"footer-social-icons unselectable"}
          />
          <Link to="/demo">
            <Button className={"footer-cta"}>SEND YOUR DEMO</Button>
          </Link>
          <h5>Hoop Records &copy;</h5>
        </div>
      </div>
    </footer>
  );
};

import "../style/AboutUs.css";
import { useState } from "react";
import { AboutUsDescription } from "../components/AboutUsDescription";
import { AboutUsPics } from "../components/AboutUsPics";

export const AboutUs = () => {
  const [cit, setCit] = useState("");

  return (
    <section id="about" className="about-us-section">
      <h2 className="section-title">ABOUT US</h2>
      <h3>Click on one of us to find out more</h3>
      <AboutUsPics cit={cit} setCit={setCit} />
      <AboutUsDescription cit={cit} />
    </section>
  );
};
